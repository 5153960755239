
/**
 * LOL
 */
export function getWeekStart() {
  type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
  type WeekInfo = {
    firstDay: DayOfWeek
  }
  type PossiblyIntlLocale = Intl.Locale & {
    getWeekInfo?: () => WeekInfo,
    weekInfo?: WeekInfo
  };
  
  const language = navigator?.language || 'en-US';
  const locale = new Intl.Locale(language) as PossiblyIntlLocale;
  const weekInfo = locale.getWeekInfo ? locale.getWeekInfo() : locale.weekInfo;
  const weekStart = (weekInfo ? weekInfo.firstDay : 0) % 7 as DayOfWeek;

  return weekStart;
}
